@use '@angular/material' as mat;

@mixin iot4bos-create-asset-from-template-dialog-theme($theme) {
  .create-asset-from-template-dialog--model-radio-button-group .mat-mdc-radio-button {
    .mat-radio-label,
    .mdc-form-field.mdc-form-field--align-end {
      flex-direction: column;
      padding-bottom: 16px;
    }
  }
}
