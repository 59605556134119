@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-radio-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  @include mat.radio-density(-1);

  mat-radio-button,
  .mat-mdc-radio-button {
    --mdc-radio-state-layer-size: 33px;
    
    color: $primary-color;

    .mdc-form-field {
      font: inherit;
    }
  }
}
