@use '@angular/material' as mat;

@mixin material-date-picker-toggle-theme($theme) {

  .mat-datepicker-toggle {
    .mat-mdc-icon-button .mat-mdc-button-ripple {
      border-radius: 0.5rem;
    }
  }
}
