@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-layout-theme($theme) {
  .layout {
    width: 100%;
    height: 100%;

    &__wrapper {
      margin: 0 auto;
      width: auto;
    }
  }
}
