@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-filter-engine-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open.mat-mdc-autocomplete-visible {
    padding: 0;
    --mdc-checkbox-state-layer-size: 20px !important;

    mat-option,
    .mat-mdc-option {
      line-height: initial;
    }

    $checkboxWidth: 16px;

    .mat-mdc-checkbox .mdc-checkbox {
      flex: 0 0 $checkboxWidth;
      width: $checkboxWidth;
      height: 20px;
      padding: 2px 0 0 0;
      margin-right: 14px;
      position: relative;
      left: -2px;

      &__ripple {
        display: none;
      }

      &__background {
        width: $checkboxWidth;
        height: $checkboxWidth;
      }

      .mat-mdc-option {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
  }

  .filter-engine-row,
  .filter-engine-row .mat-mdc-button:not(:disabled) {
    color: $primary-color;
  }

  .filter-engine-applied {
    background-color: #ffffff;
  }

  .add-button-off {
    height: 16px;
    width: 16px;
    position: relative;
    left: -25px;
    top: -2px;

    &:disabled {
      background: var(--mdc-protected-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
    }

    .mat-icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
    }
  }

  .filter-engine-max-capacity {
    color: $warn;
    font-weight: 600;
  }

  .filters-container {
    .mat-mdc-form-field {
      padding-bottom: 0;
    }

    .mat-form-field-suffix,
    .mat-mdc-form-field-suffix,
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-icon-suffix {
      .mat-mdc-icon-button {
        top: 10px;
        height: 40px;
        width: 25px;

        &:not([disabled]) {
          color: var(--mdc-icon-button-icon-color, inherit);
          background-color: transparent;
        }
      }
    }

    .mat-mdc-select-value {
      max-width: 180px;
    }

    .mat-mdc-chip.mat-mdc-standard-chip {
      .mat-mdc-chip-action-label {
        font: inherit;
      }
    }

    .mat-chip-list-wrapper:focus,
    .mat-chip-list:focus,
    .mdc-evolution-chip-set__chips:focus {
      outline: none;
    }
  }
}
