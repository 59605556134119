@use '@angular/material' as mat;
@use "sass:map";

@mixin data-guard-check-error-theme($theme) {
  $al-color-palette-orange100: map.get($theme, al-color-palette-orange100);
  $al-color-bg-layer-error: map.get($theme, al-color-bg-layer-error);
  $grey300: map.get($theme, al-color-palette-grey300);

  .detailed-error {
    &--container {
      &.warning {
        background-color: $al-color-palette-orange100;
      }

      &.error {
        background-color: $al-color-bg-layer-error;
      }
    }

    &--action-label {
      color: $grey300;
    }
  }
}
