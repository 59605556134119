@use 'sass:map';

@mixin iot-platform-ui-display-value-card-theme($theme) {
  $dpblue50: map.get($theme, al-color-palette-dpblue50);

  .iot-platform-ui-display-value-card {

    &__value {
      &:hover {
        color: #8ed0ff;
        text-decoration: underline;
      }
    }

    &__content {
      background: $dpblue50;
    }

    .simple-card {
      box-shadow: none;
      cursor: default;
    }

    .simple-card__content {
      min-height: auto;
      padding: 0;
    }
  }
}
