@use 'sass:map';
@use '@angular/material' as mat;

@use 'libs/iot-platform/iot-platform-theming/src/lib/iot-platform-theme' as *;
@use './variables' as *;
@use './palette' as palette;
@use './ui-components/ui-components' as *;
@use './overrides' as *;

$palette: map.merge($al-palette, palette.$al-palette);

$iot4bos-default-theme: map.merge($iot-platform-default-theme, $palette);
$iot4bos-pink-theme: map.merge($iot-platform-pink-theme, $palette);
$iot4bos-sand-theme: map.merge($iot-platform-sand-theme, $palette);

@mixin iot4bos-components-theme($theme) {
  @include iot-platform-theme($theme);
  @include iot4bos-ui-components-theme($theme);
  @include iot4bos-global-overrides($theme);
}

@include mat.all-component-themes($iot-platform-default-theme);

.default-theme {
  @include iot4bos-components-theme($iot4bos-default-theme);
}

.pink-theme {
  @include mat.all-component-colors($iot4bos-pink-theme);
  @include iot4bos-components-theme($iot4bos-pink-theme);
}

.sand-theme {
  @include mat.all-component-colors($iot4bos-sand-theme);
  @include iot4bos-components-theme($iot4bos-sand-theme);
}


