@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-tag-list-by-concept($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  .concept-name {
    color: $primary-color;
  }

  .tag-category-name {
    color: $primary-color;
  }
}
