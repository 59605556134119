@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-image-selector-v2-theme($theme) {
  $success-green: map.get($theme, al-color-palette-green200);

  .image-selector {
    &__check-icon {
      color: $success-green;
    }
  }
}
