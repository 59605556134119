@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-breadcrumb-item-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  .breadcrumb {
    &-item {
      background-color: $accent;
      color: $accent-color;
    }

    &-item-icon {
      color: $accent-color;
    }

    &-close-button {
      &:hover {
        background-color: $accent-color;
        color: $accent;
      }
    }

    &__back-button {}
     &__item {
       background-color: $accent;
       color: $accent-color;
       .mat-icon {
         color: $accent-color;
       }
       .mat-mdc-progress-spinner circle,
       .mat-mdc-progress-spinner circle {
         stroke: $accent-color;
       }
       &--passive {
         background-color: transparent;
         color: $accent;
         .mat-icon {
           color: $accent;
         }
         .mat-mdc-progress-spinner circle,
         .mat-mdc-progress-spinner circle {
           stroke: $accent;
         }
       }
     }
  }

  .origin,
  .breadcrumb__back-button {
    background: #a5d564;
    color: white;
  }

  .passive {
    color: $accent;
  }
}
