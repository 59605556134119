@use '@angular/material' as mat;

@mixin maps-theme($theme) {
  $bg-color: #2c3e50;
  .map {
    &__container {
      .leaflet-popup {
        .leaflet-popup-content {
          background:#2c3e50;
          color:#fff;
          font-size:11px;
          line-height:12px;
          border-radius: 10px 10px 0px 10px;

          p {
            margin: .5em 0 !important;
          }
          &-wrapper {
            text-transform: capitalize;
            background:$bg-color;
            color:#fff;
            border-radius: 10px 10px 0px 10px;
          }
          &__section {
            display: flex;
            flex-direction: row;
            justify-content: center;
            &_label {
              width: 30%;
              display: flex;
              justify-content: space-between;
              margin-right: 1px;
            }
            &_value {
              width: 70%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
        .leaflet-popup-tip {
          background: transparent;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}
