@use '@angular/material' as mat;
@use "sass:map";

@use '../../../src/lib/components/roles-crud/roles-crud.component.scss-theme' as *;
@use '../components/dialogs/add-business-profile-dialog/add-business-profile-dialog.component.scss-theme' as *;
@use '../components/dialogs/add-user-dialog/add-user-dialog.component.scss-theme' as *;

@mixin backoffice-ui-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  @include role-crud-theme($theme);
  @include add-business-profile-dialog-theme($theme);
  @include add-user-dialog-theme($theme);

  .mv-toolbar-row {
    font-size: 16px;
    line-height: 30px;
    color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
    background-color: mat.m2-get-color-from-palette(map.get($theme, primary), default);
    width: 100%;
  }

  .mv-toolbar-col {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: mat.m2-get-color-from-palette(map.get($theme, primary), default);

    .app-round-button-mv {
      color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
    }
  }

  .mv-toolbar-label {
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
  }

  .bp-page {
    height: 100%;
    overflow: hidden;
  }

  .no-profile {
    padding-top: 20px;
    font-size: 18px;
    color: #797979;
    font-weight: 600;
    text-align: center;
  }

  .icon-disabled-white.mat-mdc-icon-button[disabled] {
    color: $accent-color;
    opacity: 0.4;
  }
}
