@use '@angular/material' as mat;
@use "sass:map";

@mixin followed-asset-variables-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .followed-variable-info,
  .followed-variable-icons,
  .followed-variable-header {
    color: $primary-color;
  }

  .followed-variable-dispatch-icon,
  .followed-variable-comment,
  .followed-variable,
  .followed-variable-date,
  .followed-variable-placeholder,
  .followed-variable-actions {
    color: #b0b0b0;
  }

  .followed-variable-placeholder {
    border: 1px dashed #b0b0b0;
  }

  .followed-variable-linked.followed-variable-icons {
    color: $accent-color;
    background-color: $accent;
  }

  .followed-variable-alert.followed-variable-icons {
    background-color: $warn;
    color: $accent-color;
  }
}
