@use 'sass:map';

$minHeight: 40px;
$padding: 5px;

@mixin iot-platform-ui-drag-and-drop-theme($theme) {
  $grey100: map.get($theme, al-color-palette-grey100);
  $mediumGrey: map.get($theme, al-medium-grey);
  $iconSupporting: map.get($theme, al-color-icon-supporting);

  .cdk-drag-drop-container,
  .cdk-drag-preview,
  .cdk-drag-dragging {
    .cdk-drag-drop-element-container {
      min-height: $minHeight;
      background: $grey100;
      height: auto;

      &--has-no-drag-indicator {
        .mat-expansion-panel-header:not([aria-disabled='true']) {
          cursor: move !important;
        }
      }
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .cdk-drop-list-dragging iot-platform-ui-drag-drop-element:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .dd-handle-icon {
      cursor: move;
    }

    .dd-action-icon {
      cursor: pointer;
    }

    .dd-handle-icon,
    .dd-action-icon {
      color: $iconSupporting !important;
      padding: 5px;
      min-width: 24px;
    }

    .cdk-drag-placeholder {
      background-color: $grey100;
      border: dotted 2px $mediumGrey;
      border-radius: 8px;
      margin: 5px 0;
      height: auto;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

      &--default {
        min-height: $minHeight;
      }
    }
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
    height: auto !important;
  }
}
