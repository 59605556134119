@use '@angular/material' as mat;
@use 'sass:map';
@use 'libs/iot-platform/iot-platform-theming/src/lib/variables' as *;

@mixin iot-platform-ui-sidenav-theme($theme) {
  $dpblue900: map.get($theme, al-color-palette-dpblue900);

  .sidenav-menu-panel {
    background-color: $dpblue900;
    width: 250px;
    max-height: 100%;
    position: absolute;
    top: -36px;
    left: calc(#{$app-sidenav-width} - 4px);
    border-radius: 0;
    min-height: 40px;
    overflow-x: hidden;

    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
    }
  }

  .sidenav-backdrop-class {
    left: $app-sidenav-width;
  }

  iot-platform-ui-sidenav-block {
    header {
      color: #b0b0b0;
    }

    .mat-list-item-content,
    .mdc-list-item__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .inSubMenu {
      .mat-list-item-content,
      .mdc-list-item__content {
        box-sizing: border-box;
        padding: 10px 0 10px 5px;
      }
    }

    &.sidenav-menu-panel-block .mat-mdc-list-item.mdc-list-item--with-one-line {
      height: 35px;
    }
  }

  .iot-platform-ui-sidenav-item {
    &.container {
      padding-bottom: 0;
    }

    .mat-mdc-list-item {
      &:hover {
        background-color: #303f4b;
      }
    }

    .mat-icon.item-icon,
    .chevron-right {
      color: white;
    }

    p {
      color: white !important;
    }

    .active-item {
      border-left-color: white !important;
    }
  }
}
