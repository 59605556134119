@use '@angular/material' as mat;
@use "sass:map";

@mixin data-guard-overview-check-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);

  .overview-check {
    &--container {
      &:not(.isCurrent):hover {
        background-color: $primary;
      }
    }
  }
}
