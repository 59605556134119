@use '@angular/material' as mat;
@use "sass:map";

@mixin add-business-profile-dialog-theme($theme) {
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .dialog-card-input-error {
    color: $warn;
  }
}
