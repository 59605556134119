@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-association-card-container-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $grey100: map.get($theme, al-color-palette-grey100);

  .iot-platform-ui-association-card-container {
    .overview-card {
      padding: 8px !important;
      flex-direction: row !important;

      .overview-card-img-container {
        max-width: 33% !important;
        margin-right: 16px;
        margin-bottom: 0 !important;
      }

      @media (min-width: 500px) {
        &__property {
          flex: 1 1 50% !important;
          box-sizing: border-box !important;
          max-width: 50% !important;
        }
      }

      &-name {
        font-size: 14px !important;
      }

      &__property-label,
      &__property-value {
        padding: 7px 0 !important;
      }
    }
  }
}
