@use '@angular/material' as mat;
@use "sass:map";

@mixin iot-platform-app-shell-theme($theme) {
  $primary-darker: mat.m2-get-color-from-palette(map.get($theme, accent), darker);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $dpblue900: map.get($theme, al-color-palette-dpblue900);
  $grey700: map.get($theme, al-color-palette-grey700);

  header {
    color: $primary-color;
  }

  button.mat-mdc-menu-item {
    text-transform: uppercase;
  }

  .navigation-bar {
    background-color: $dpblue900;
    color: #fff;
  }

  .border-right {
    &::after {
      border-right: 1px solid $primary-color;
    }
  }

  .border-left {
    &::before {
      border-left: 1px solid $primary-color;
    }
  }

  .sidenav {
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 20px;
      background-color: #394c5a;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: $grey700;
    }
  }
}
