@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-slider-toggle-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  .mat-mdc-slide-toggle {
    .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
      background: $primary-color;
    }
  }
}
