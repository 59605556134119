@use '@angular/material' as mat;

@mixin iot-platform-ui-form-field-theme($theme) {
  .iot-platform-ui-form-field {
    .mat-mdc-text-field-wrapper {
      margin-bottom: 4px !important;
    }

    .mat-mdc-form-field-error-wrapper {
      top: -4px !important;
    }
  }
}
