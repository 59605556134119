@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-manage-tag-form-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .selected-tags {
    background-color: $primary;
    color: $primary-color;
  }

  .tag-list-accordion .mat-expansion-panel-body {
    margin-left: 20px;
    padding: 0 16px;
    border-left: 1px solid $primary-color;
  }

  .mandatory-warning {
    color: $warn;
  }
}
