@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-chip-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  .mdc-evolution-chip-set {
    margin-left: 7px;
    flex: 1 1 0;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    background-color: $accent;

    .mat-mdc-chip-action-label {
      color: $accent-color;
      font-size: 12px;

      display: flex;
      justify-content: center;
      align-items: center;

      mat-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
