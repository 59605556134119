@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-chat-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .message-author {
    color: $primary-color;
  }

  .message-icon,
  .message-date {
    color: #b0b0b0;
  }

  .action-icon {
    color: #394c5a;
  }

  .message-value {
    color: $primary-color;
    background-color: $primary;
  }

  .chat-container {
    background-color: $accent-color;
  }

  .chat-header-container {
    padding: 15px 20px;
  }

  .chat-filters-container {
    color: $primary-color;
    background-color: #f7f8f7;
  }

  .chat-footer-container {
    padding: 15px 20px;
  }

  .mat-mdc-button-touch-target {
    display: none;
  }
}
