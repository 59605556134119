@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-select-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  .mat-mdc-option.mdc-list-item.mdc-list-item--disabled {
    cursor: not-allowed;
    pointer-events: initial;
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0;
  }
  .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(0px);
  }
  .mat-mdc-select-date-range-overlay-pane,
  .mat-mdc-select-fv-overlay-pane,
  .mat-mdc-select-bp-overlay-pane {
    width: auto !important;
  }

  .mat-mdc-select-fv-overlay-pane {
    min-width: 17rem;
  }

  .mat-mdc-select-bp-overlay-pane {
    min-width: 13rem;

    .mat-mdc-option:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 13rem;
    }
  }

  mat-select,
  .mat-mdc-select {
    letter-spacing: normal;
    line-height: 18px;

    .mat-mdc-select-trigger {
      height: 18px;
    }
  }

  mat-select,
  .mat-mdc-select {
    &:not(.mat-mdc-select-disabled) {
      .mat-mdc-select-value,
      .mat-mdc-select-value-text {
        color: $primary-color;
      }
    }
  }

  .mat-mdc-form-field-infix {
    mat-select,
    .mat-mdc-select {
      font-size: 12px;

      .mat-mdc-select-trigger {
        // top: 4px;
        // position: relative;
      }
    }

    label.mat-form-field-label,
    .mat-mdc-floating-label,
    mat-label {
      font-size: 14px;
    }
  }

  mat-option,
  .mat-mdc-option {
    min-height: 36px;
    line-height: 36px;
    font-size: 12px;

    .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked {
      display: none !important;

      &::after {
        width: 10px;
        height: 4px;
      }
    }

    &:not(.mdc-list-item--disabled) {
      .mat-option-text,
      .mdc-list-item__primary-text {
        color: $primary-color;
      }
    }

    &.mat-mdc-selected,
    &.mdc-list-item--selected {
      &:not(.mdc-list-item--disabled) {
        .mat-option-text,
        .mdc-list-item__primary-text {
          color: $primary-color !important;
        }
      }

      .mdc-list-item__primary-text {
        color: inherit;
      }
    }
  }

  .mdc-list-item__primary-text,
  .mat-option-text {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font: inherit;
  }

  .mat-mdc-optgroup-label {
    font-size: 14px;
    height: 28px;
    min-height: 28px;
    line-height: 28px;
    font-weight: 600;

    .mat-option-text,
    .mdc-list-item__primary-text {
      white-space: normal;
      color: #0000008a;
    }
  }
}
