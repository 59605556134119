@use '@angular/material' as mat;
@use "sass:map";

@mixin home-shell-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .home-favorite-view-list-title {
    color: #7e7e7e;
  }

  .no-favorite-view-message {
    color: #7e7e7e;
  }
}
