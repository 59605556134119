@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-device-latest-command-status-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);
  $warn-color: mat.m2-get-color-from-palette(map.get($theme, warn), default-contrast);

  .dlcs-container {
    color: $primary-color;
  }

  .dlcs-step-ongoing {
    background-color: $accent;
    color: $accent-color;

    .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
      stroke: $accent-color;
    }
  }

  .dlcs-step-validated {
    background-color: #e4f4d0;
    color: $primary-color;

    .mat-icon,
    .mat-progress-spinner {
      color: $primary-color;
    }
  }

  .dlcs-step-error {
    background-color: #f5d9dc;
    color: $warn;

    .mat-icon,
    .mat-progress-spinner {
      color: $warn;
    }
  }
}
