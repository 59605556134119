@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables' as *;

@mixin material-input-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default); //default-contrast
  $error-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-red-palette, 300, 300, 300));
  $text-secondary: map.get($theme, al-color-text-secondary);

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    line-height: normal;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    --mdc-filled-text-field-input-text-color: #394c5a;
    color: var(--mdc-filled-text-field-input-text-color);
  }

  .async-autocomplete-multiple-selects,
  .iot-platform-ui-async-autocomplete {
    .iot-platform-ui-form-field {
      margin-top: 2px;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
      // line-height: 18px;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    font-size: 80%;
    padding: 0;
  }

  .mat-mdc-form-field {
    font-size: inherit;
    line-height: 1.125;
    font-family: Open Sans, sans-serif;
    letter-spacing: normal;

    &:hover .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }

    .mdc-text-field {
      overflow: unset;
    }

    mat-label,
    .mat-mdc-floating-label,
    .mat-mdc-form-field-error {
      letter-spacing: normal;
    }

    mat-label,
    .mat-mdc-floating-label {
      color: $text-secondary;
    }

    .mat-icon.search-icon {
      font-size: 18px;
      vertical-align: sub;
    }

    &.mat-focused {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0
      }
    }

    &.mat-accent {
      .mdc-text-field {
        &--focused,
        &--invalid.mdc-text-field--focused {
          &:not(.mdc-text-field--disabled) {
            mat-label,
            .mat-mdc-floating-label {
              // font-weight: 500;
            }
          }
        }

        &--focused {
          &:not(.mdc-text-field--disabled) {
            mat-label,
            .mat-mdc-floating-label {
              color: $accent;
            }
          }
        }

        &--invalid {
          &:not(.mdc-text-field--disabled) {
            mat-label,
            .mat-mdc-floating-label {
              color: var(--mdc-theme-error, #bf5f69) !important;
            }
          }
        }
      }
    }

    .mdc-text-field {
      &--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
      }

      &--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding: 0.4375em 0;
      }

      &--disabled {

        &.mdc-text-field--filled {
          background: transparent;
        }

        .mdc-line-ripple::before {
          border-bottom-style: dashed;
          border-bottom-color: rgba(0, 0, 0, .42);
        }
      }
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;

      .mat-mdc-form-field-icon-prefix {
        padding: 0;
      }
    }

    .mat-mdc-form-field-infix {
      min-height: auto;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 22px;
        font-size: 14px;
        width: 100%;

        mat-label {
          font-size: 14px;
          width: 100%;
        }
      }

      label.mdc-floating-label[for^="mat-select"] {
        top: 24px !important;
      }

      &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding: 17px 0 5px 0;
      }
    }

    .mat-mdc-form-field-hint-wrapper {
      font-size: 75%;
      padding: 0;
      top: -4px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 15px;
    }
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0;
  }

  .mat-mdc-form-field {
    .mat-mdc-icon-button .mat-icon {
      margin: 0;
    }
  }
}
