@use 'sass:map';
@use './variables' as *;

@mixin apply-al-theme($theme) {
  $red300: map.get($theme, al-color-palette-red300);
  $red400: map.get($theme, al-color-palette-red400);
  $green200: map.get($theme, al-color-palette-green200);
  $green400: map.get($theme, al-color-palette-green400);
  $wgreen100: map.get($theme, al-color-palette-wgreen100);
  $blue50: map.get($theme, al-color-palette-blue50);
  $blue100: map.get($theme, al-color-palette-blue100);
  $blue300: map.get($theme, al-color-palette-blue300);
  $orange50: map.get($theme, al-color-palette-orange50);
  $orange100: map.get($theme, al-color-palette-orange100);
  $orange200: map.get($theme, al-color-palette-orange200);
  $orange300: map.get($theme, al-color-palette-orange300);
  $orange400: map.get($theme, al-color-palette-orange400);
  $orange700: map.get($theme, al-color-palette-orange700);
  $yellow300: map.get($theme, al-color-palette-yellow300);
  $yellow500: map.get($theme, al-color-palette-yellow500);
  $yellow600: map.get($theme, al-color-palette-yellow600);
  $text-primary: map.get($theme, al-color-text-primary);
  $text-secondary: map.get($theme, al-color-text-secondary);
  $text-error: map.get($theme, al-color-text-error);

  .bg-red300 {
    background: $red300 !important;
  }

  .bg-red400 {
    background: $red400 !important;
  }

  .bg-green200 {
    background: $green200 !important;
  }

  .bg-green400 {
    background: $green400 !important;
  }

  .bg-wgreen100 {
    background: $wgreen100 !important;
  }

  .bg-blue300 {
    background: $blue300 !important;
  }

  .bg-blue100 {
    background: $blue100 !important;
  }

  .bg-blue50 {
    background: $blue50 !important;
  }

  .bg-orange50 {
    background: $orange50 !important;
  }

  .bg-orange100 {
    background: $orange100 !important;
  }

  .bg-orange200 {
    background: $orange200 !important;
  }

  .bg-orange300 {
    background: $orange300 !important;
  }

  .bg-orange400 {
    background: $orange400 !important;
  }

  .bg-orange700 {
    background: $orange700 !important;
  }

  .bg-yellow300 {
    background: $yellow300 !important;
  }

  .bg-yellow500 {
    background: $yellow500 !important;
  }

  .bg-yellow600 {
    background: $yellow600 !important;
  }

  .bg-grey0 {
    background: rgb(242 242 242) !important;
  }

  .text-primary {
    color: $text-primary;
  }

  .text-secondary {
    color: $text-secondary;
  }

  .text-error {
    color: $text-error;
  }
}

@mixin iot-platform-link($theme) {
  $blue300: map.get($theme, al-color-palette-blue300);

  .link {
    text-decoration: none;
    color: $blue300;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@mixin iot-platform-mixins($theme) {
  @include apply-al-theme($theme);
  @include iot-platform-link($theme);
}
