@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-list-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  .mat-mdc-list-base {
    padding-top: 0;
  }

  .mdc-list-item {
    .mat-mdc-list-item-title {
      padding: 0;
    }

    .mdc-list-item__content {
      display: flex;
      flex-direction: column;
      align-self: center;

      .mdc-list-item__primary-text {
        color: #394c5a;
      }

      .mdc-list-item__primary-text::before {
        height: auto;
      }
    }

    .mdc-list-item--with-leading-icon,
    .mdc-list-item__start,
    &.mdc-list-item--with-two-lines .mdc-list-item__start,
    &.mdc-list-item--with-three-lines .mdc-list-item__start,
    &--with-leading-icon .mdc-list-item__start,
    &--with-leading-icon .mdc-list-item__end,
    &.mdc-list-item--with-trailing-meta .mdc-list-item__start,
    &.mdc-list-item--with-trailing-meta .mdc-list-item__end {
      align-self: center;
      margin-top: 0;
      color: inherit;
    }

    &--with-leading-icon .mdc-list-item__start {
      margin-right: 15px;
    }
  }


  mat-list.mat-mdc-list-base .mat-mdc-list-item,
  mat-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-one-line,
  mat-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-two-lines,
  mat-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-three-lines {
    color: $primary-color;

    &.selected,
    &.hoverable:hover {
      background-color: $accent;
      color: $accent-color;

      .mat-mdc-list-item-title.mdc-list-item__primary-text,
      .mat-mdc-list-item-line.mdc-list-item__secondary-text {
        background-color: $accent;
        color: $accent-color;
      }
    }
  }

  mat-nav-list.mat-mdc-list-base .mat-mdc-list-item,
  mat-nav-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-one-line,
  mat-nav-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-two-lines,
  mat-nav-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-three-lines {
    color: $primary-color;

    &.fs-12 {
      font-size: 12px;
    }

    &.selected,
    &.hoverable:hover {
      background-color: $accent;
      color: $accent-color;

      .mat-mdc-list-item-title.mdc-list-item__primary-text,
      .mat-mdc-list-item-line.mdc-list-item__secondary-text {
        background-color: $accent;
        color: $accent-color;
      }
    }
  }

  mat-list.mat-mdc-list-base .mat-mdc-list-item,
  mat-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-one-line,
  mat-nav-list.mat-mdc-list-base .mat-mdc-list-item,
  mat-nav-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-one-line {
    height: 32px;
  }

  mat-list.height-300,
  .mat-mdc-list.height-300,
  {
    min-height: 300px;
    max-height: 300px;
    overflow: auto;
  }


  .mat-mdc-list.two-line-list.mat-mdc-list-base .mdc-list-item.mdc-list-item--with-two-lines,
  mat-list.two-line-list.mat-mdc-list-base .mat-mdc-list-item.mat-2-line,
  mat-nav-list.two-line-list.mat-mdc-list-base .mat-mdc-list-item.mat-list-item-with-avatar,
  mat-nav-list.mat-mdc-list-base.two-line-list .mat-mdc-list-item.mdc-list-item--with-leading-avatar {
    height: 50px;

    .mat-mdc-list-item-avatar {
      width: 24px;
      height: 24px;
    }
  }

  mat-list.one-line-list.mat-mdc-list-base .mat-mdc-list-item.mat-mdc-list-item-with-avatar,
  mat-list.one-line-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-leading-avatar,
  mat-nav-list.one-line-list.mat-mdc-list-base .mat-mdc-list-item.mat-list-item-with-avatar,
  mat-nav-list.one-line-list.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-leading-avatar {
    height: 40px;

    .mat-mdc-list-item-avatar {
      width: 24px;
      height: 24px;
    }
  }
}
