@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables' as *;

@mixin material-dialog-theme($theme) {
  $text-secondary: map.get($theme, al-color-text-secondary);

  .mat-mdc-card {
    &.dialog-card {
      margin: 0px;
      padding: 0px;
    }

    .mat-mdc-card-title.dialog-card-header {
      margin: 0px;
      padding: 0px;
    }

    .mat-mdc-card-content {
      font-size: 14px;
    }

    .mat-mdc-card-content {
      padding: 0;

      &.dialog-card-content,
      .dialog-card-content {
        margin: 0;
        color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast) !important;

        &__section-header {
          color: $text-secondary;
        }
      }
    }

    .mat-mdc-card-actions {
      &.dialog-card-actions {
        display: flex;
        background-color: $light-grey;
        margin: 0px !important;
        padding: 10px !important;
        place-content: center flex-end;
        align-items: center;
        flex-direction: row;
      }

      &:not(.mat-mdc-card-actions-align-end) .mdc-button:first-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }


  .mat-mdc-dialog-container {
    padding: 0px !important;
    border-radius: $app-border-radius !important;
    max-height: calc(100vh - 1rem);

    .matButton {
      border-radius: $app-border-radius !important;
    }
  }

  .dialog-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: normal;
  }

  mat-toolbar.dialog-toolbar {
    display: flex;
    margin: 0px;
    place-content: center space-between;
    align-items: center;
    flex-direction: row;

    h1 {
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .dialog-form-tips {
    font-size: 10px;
    line-height: 10px;
    font-style: italic;
    color: $medium-grey;
  }

  .cdk-global-overlay-wrapper {
    overflow: auto;
  }
}
