@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-status-button-theme($theme) {
  $text-white: map.get($theme, al-color-text-white);
  $orange100: map.get($theme, al-color-palette-orange100);
  $orange700: map.get($theme, al-color-palette-orange700);
  $blue100: map.get($theme, al-color-palette-blue100);
  $brand-blue: map.get($theme, al-color-brand-blue);
  $red400: map.get($theme, al-color-palette-red400);
  $red300: map.get($theme, al-color-palette-red300);
  $red50: map.get($theme, al-color-palette-red50);
  $text-error: map.get($theme, al-color-text-error);

  .iot-platform-ui-status-button {
    &--pending {
      &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled) {
        border-color: $orange700 !important;
        background: $orange100;
        color: $orange700;
      }

      .iot-platform-ui-status-button__child.mat-mdc-outlined-button:not(:disabled), {
        border-color: $orange700 !important;
        background: $orange700;
        color: $text-white;
      }

      &.stroke {
        &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled) {
          border-color: $orange700 !important;
          background: $orange100;
          color: $orange700;
        }

        .iot-platform-ui-status-button__child.mat-mdc-outlined-button:not(:disabled) {
          border-color: $orange700 !important;
          background: $orange100 !important;
          color: $orange700;
        }

        &.text-error {
          &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled),
          .iot-platform-ui-status-button__child.mat-mdc-outlined-button:not(:disabled) {
            .iot-platform-ui-status-button__label {
              color: $text-error;
            }
          }
        }
      }
    }

    &--published {
      &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled) {
        border-color: $brand-blue !important;
        background: $blue100;
        color: $brand-blue;
      }

      &.stroke {
        &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled) {
          border-color: $text-white !important;
          background: $blue100;
          color: $brand-blue;
        }

        .iot-platform-ui-status-button__child.mat-mdc-outlined-button:not(:disabled) {
          border-color: $brand-blue !important;
          background: $blue100;
          color: $brand-blue;
        }
      }
    }

    &--error {
      &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled) {
        border-color: $red400 !important;
        background: $red50;
        color: $red400;
      }

      .iot-platform-ui-status-button__child.mat-mdc-outlined-button:not(:disabled), {
        border-color: $red400 !important;
        background: $red300;
        color: $text-white;
      }

      &.stroke {
        &.iot-platform-ui-status-button__parent.mat-mdc-outlined-button:not(:disabled) {
          border-color: $red400 !important;
          background: $red50;
          color: $red400;
        }

        .iot-platform-ui-status-button__child.mat-mdc-outlined-button:not(:disabled) {
          border-color: $red400 !important;
          background: $red50 !important;
          color: $red400;
        }
      }
    }

    &.no-border {
      border: none !important;
    }
  }
}
