@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-label-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  mat-label {
    color: $primary-color;
    letter-spacing: normal;
  }
}
