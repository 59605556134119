@use 'sass:map';

@mixin iot-platform-ui-infos-display-card-theme($theme) {
  $icon-primary: map.get($theme, al-color-icon-primary);
  $text-white: map.get($theme, al-color-text-white);
  $text-secondary: map.get($theme, al-color-text-secondary);
  $text-inactive: map.get($theme, al-color-text-inactive);
  $blue300: map.get($theme, al-color-palette-blue300);
  $green200: map.get($theme, al-color-palette-green200);
  $orange300: map.get($theme, al-color-palette-orange300);
  $red300: map.get($theme, al-color-palette-red300);
  $grey300: map.get($theme, al-color-palette-grey300);

  .iot-platform-ui-infos-display-card {
    &--with-separator {
      .iot-platform-ui-infos-display-card-property {
        &:not(:last-child) {
          border-right: 1px solid $grey300;
        }
      }
    }

    .iot-platform-ui-infos-display-card-property {
      &--disabled {
        .iot-platform-ui-infos-display-card-property__value {
          color: $text-inactive;
        }
      }

      &__label,
      &__value_meta-info:not(:disabled) {
        color: $text-secondary;
      }

      &__value {
        margin-right: 10px;

        &_meta-info,
        &_meta-info mat-icon {
          width: 16px;
          height: 16px;
          font-size: 16px;
        }

        .event-severity-display-container {
          width: auto !important;
          min-width: 69px !important;
          padding: 0 !important;
        }
      }
    }

    &--info {
      .iot-platform-ui-infos-display-card__label {
        background: $blue300;
        color: $text-white;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $blue300;
      }
    }

    &--success {
      .iot-platform-ui-infos-display-card__label {
        background: $green200;
        color: $icon-primary;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $green200;
      }
    }

    &--warning {
      .iot-platform-ui-infos-display-card__label {
        background: $orange300;
        color: $icon-primary;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $orange300;
      }
    }

    &--danger {
      .iot-platform-ui-infos-display-card__label {
        background: $red300;
        color: $text-white;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $red300;
      }
    }
  }
}
