@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-timeline-list-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $font-size: 12px;
  $color: #394c5a;
  $border: 1px solid lightgrey;

  .timeline-list {
    &__title {
      color: $accent;
    }

    iot-platform-ui-timeline-list-item {
      border-top: $border;

      &:last-child {
        border-bottom: $border;
      }

      .separator {
        border-left: $border;
      }
    }

    &__item {
      font-size: $font-size;
      color: $color;
    }
  }
}
