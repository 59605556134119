@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables' as *;

@mixin material-chip-theme($theme) {
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);

  .chip-filter {
    &.mat-mdc-chip.mat-mdc-standard-chip {
      background-color: #f8f8f8;
      border: 1px solid #c5c5c5;
      color: #394c5a;

      .mat-mdc-chip-action-label {
        color: #394c5a;
        font-size: 10px;
      }

      .mdc-evolution-chip__action--primary:hover {
        cursor: move;
      }
    }

    &.cdk-drag-placeholder,
    &.cdk-drag-preview {
      .mat-mdc-chip-action-label {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
    }

    .mat-mdc-chip-focus-overlay,
    .mat-mdc-chip-ripple {
      margin-right: 0 !important;
    }
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    height: 20px;
    margin: 1px;

    .mat-mdc-chip-action-label {
      color: $accent-color;
      letter-spacing: normal;
      font-weight: 600;
      font-size: 10px;
    }

    .mat-mdc-focus-indicator {
      background: transparent !important;
      opacity: .54 !important;
    }

    &:hover::after {
      opacity: 0;
    }

    &:active {
      box-shadow: none;
    }

    &:focus::after {
      opacity: 0;
    }

    mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
  }

  .mat-chip-list-wrapper,
  .mdc-evolution-chip-set__chips {
    margin: 0;
  }
}
