@use "sass:map";

@mixin iot-platform-ui-page-title-card-theme($theme) {
  $wgreen200: map.get($theme, al-color-palette-wgreen200);
  $wgreen600: map.get($theme, al-color-palette-wgreen600);

  .iot-platform-ui-page-title-card {
    background-color: $wgreen200;

    &__label {
      color: $wgreen600;
    }
  }
}
