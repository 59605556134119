@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-checkbox-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  @include mat.checkbox-density(-1);

  .mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 26px;
    --mdc-checkbox-ripple-size: 16px;
    --mdc-checkbox-touch-target-size: 33px;

    &.mat-mdc-checkbox-disabled {
      .mdc-checkbox,
      .mdc-label {
        cursor: not-allowed;
        pointer-events: initial;
      }
    }

    label {
      font-size: 12px;
      letter-spacing: normal;
    }

    .mdc-checkbox {
      flex: 0 0 14px;
      width: 14px;
      height: 14px;

      .mdc-checkbox__background {
        height: 14px;
        width: 14px;
      }

      .mdc-checkbox__native-control {
        top: calc((25px - var(--mdc-checkbox-touch-target-size, 25px)) / 2);
        right: calc((25px - var(--mdc-checkbox-touch-target-size, 25px)) / 2);
        left: calc((25px - var(--mdc-checkbox-touch-target-size, 25px)) / 2);
        width: var(--mdc-checkbox-touch-target-size, 25px);
        height: var(--mdc-checkbox-touch-target-size, 25px);
      }

      .mdc-checkbox__ripple {
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        right: calc(50% - 13px);
        bottom: calc(50% - 13px);
        border-radius: 50%;
      }

      .mat-mdc-checkbox-touch-target {
        height: 30px;
        width: 30px;
      }
    }
  }

  .mat-pseudo-checkbox {
    width: 16px;
    height: 16px;
  }
}
