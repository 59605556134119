@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-widget-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);

  .dynamic-widget_chart {
    .variables-closed {
      width: 100% !important;
      min-width: 100% !important;
    }
    &--xs,
    &--sm,
    &--md,
    &--lg,
    &--xl {
      height: 15rem;
    }
    &--xs,
    &--sm,
    &--md {
      .variable-chart-days-filter {
        margin-bottom: 0.2rem;
      }
      .variable-chart-export-bar {
        flex-direction: column !important;
      }
      .variable-chart-scale-button-group,
      .variable-chart-selector-button,
      .variable-chart-date-picker {
        width: 100% !important;
        margin: 0.2rem 0;
      }
      .variable-chart-date-picker {
        min-width: 100%;
      }
    }

    &--xs,
    &--sm {
      .variable-chart-days-filter,
      .variable-chart-scale-button-group,
      .variable-chart-dates-filter {
        flex-direction: column !important;
        width: 100%;
      }
      .variable-chart-datepicker {
        min-width: 100% !important;
      }
    }

    &--md {
      .variable-chart-scale-button {
        width: 50% !important;
      }
    }
    &--lg,
    &--xl {
      .variable-chart-export-bar {
        flex-wrap: wrap;
      }
    }
  }

  .widget-item {
    .mat-drawer-container {
      height: 100%;
    }

    .mat-mdc-card {
      &.dialog-card {
        margin: 0px;
        padding: 0px;
        border-radius: 1rem;
      }

      .mat-toolbar {
        height: 2.5rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      .dialog-card-actions {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }

    .cdk-drop-list {
      display: flex;
      padding-right: 1rem;
      padding-bottom: 1rem;
    }

    .cdk-drag-preview {
      box-sizing: border-box;
      border-radius: 1rem;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
      margin: 0;
      padding: 0;

      .mat-toolbar.mat-accent {
        background: $accent;
        color: white;
      }

      .dialog-card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .cdk-drag-placeholder {
      opacity: 0.3;
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
