@use '@angular/material' as mat;
@use "sass:map";

@mixin variable-parameters-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);
  $success-green: map.get($theme, al-success-green);

  .asset-variable-parameter {
    &.valid-unit {
      background-color: $success-green;
    }

    &.invalid-unit {
      background-color: #f5d9dc;
    }

    &.untouched-form {
      background-color: $primary;
    }
  }

  .valid-unit-icon {
    color: $primary-color;
  }

  .invalid-unit-icon {
    color: $warn;
  }
}
