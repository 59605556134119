@use 'sass:map';

@mixin iot4bos-ui-association-by-asset($theme) {
  $grey50: map.get($theme, al-color-palette-grey50);
  $grey100: map.get($theme, al-color-palette-grey100);
  $grey200: map.get($theme, al-color-text-inactive);
  $blue100: map.get($theme, al-color-palette-blue100);
  $text-primary: map.get($theme, al-color-text-primary);

  .association-by-asset {
    .overview-card {
      box-shadow: none !important;
    }

    .default-card {
      cursor: default;
      height: 76px !important;
      max-height: 76px !important;
      min-height: 76px !important;
    }

    &__asset-group {
      background-color: $grey100;
    }

    &__name {
      color: $text-primary;
    }

    &__asset {
      background-color: white;
      border: 1px solid $grey200;

      &-card {
        .overview-card {
          padding: 0px !important;

          .overview-card-img-container {
            width: 120px !important;
            height: 80px !important;
            max-width: 120px !important;
            max-height: 80px !important;
          }

          .overview-card-header {
            margin-bottom: 8px !important;
            margin-top: 4px !important;
          }

          .overview-card-data-container {
            padding: 7px 0 !important;
          }

          .overview-card-content {
            margin-top: 0 !important;
          }

          .overview-card__property-label,
          .overview-card__property-value {
            padding-top: 0 !important;
            padding-bottom: 6px !important;
          }
        }

        &--selected {
          .overview-card {
            padding: 8px !important;
            background-color: $blue100 !important;
          }
        }
      }
    }

    &__device {
      .overview-card {
        background-color: $grey50 !important;

        .overview-card-img-container {
          width: 80px !important;
          height: 60px !important;
          max-width: 80px !important;
          max-height: 60px !important;
        }

        .overview-card-header {
          margin-bottom: 8px !important;
          margin-top: 4px !important;
        }

        .overview-card-data-container {
          padding: 0 !important;
        }

        .overview-card-content {
          margin-top: 0 !important;
        }

        .overview-card__property-label,
        .overview-card__property-value {
          padding-top: 0 !important;
          padding-bottom: 6px !important;
        }
      }

      &--selected {
        .overview-card {
          background-color: $blue100 !important;
        }
      }
    }
  }
}
