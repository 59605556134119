@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-tag-editor-form-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .cdk-overlay-pane.tag-color-select {
    width: 6rem !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-action-label {
    font-size: 10px;
  }

  .tag-editor-form-container {
    button.button-regular {
      min-width: 0 !important;
      height: 30px !important;
      line-height: 30px;
      width: auto;
    }
  }

  .tag-editor-duplicate-warning {
    color: $warn;
  }
}
