@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-menu-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  .mat-mdc-menu-panel {
    min-height: 48px;

    &.height-300 {
      max-height: 300px;
      overflow: auto;
    }
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-trigger {
    text-transform: uppercase;
  }

  button.mat-mdc-menu-item,
  button.mat-mdc-menu-item .mdc-list-item__primary-text {
    text-transform: uppercase;
  }

  .mat-mdc-menu-item {
    color: $primary-color;
    font-size: 12px;
    height: 32px;
    min-height: auto;
    line-height: 16px;
    text-transform: uppercase;

    &[disabled] {
      color: inherit;
    }

    .mat-icon {
      color: inherit;
    }
  }
}
