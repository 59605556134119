@use 'sass:map';

@mixin iot-platform-ui-default-card-theme($theme) {
  $color-secondary: map.get($theme, al-color-text-secondary);

  iot-platform-ui-default-card {
    .default-card {
      background-color: transparent;
      border: 1px dotted $color-secondary;
      border-radius: 8px;
      color: $color-secondary;

      &--with-custom-btn {
        .default-card__custom-button,
        .default-card__custom-button > * {
          position: absolute;
          width: 100%;
          height: 100%;

          button {
            width: inherit;
            height: inherit;
          }
        }

        .default-card-title {
          padding-left: 1.5rem;
        }
      }
    }
  }
}
