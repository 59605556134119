@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-section-header-theme($theme) {
  $text-secondary: map.get($theme, al-color-text-secondary);
  $stroke-primary: map.get($theme, al-color-stroke-primary);

  .iot-platform-ui-section-header {
    color: $text-secondary;

    &__title-section {
      display: grid !important;
      max-width: 70%;
    }

    &__action {
      &:not(:disabled) {
        .iot-platform-ui-section-header__action-icon {
          color: $text-secondary;
        }
      }
    }

    &__line {
      background-color: $stroke-primary;
    }
  }
}
