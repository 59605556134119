@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-timeline-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $grey: #e3e3e3;

  .timeline {
    &__header_label,
    &__header_content,
    &__dot_icon,
    &__dot_label,
    &__dot_content {
      color: $accent;
    }

    &__dot {
      &_icon {
        border-color: $accent;
        background-color: white;
      }
      &::after {
        border-bottom: 2px dotted $accent;
      }
    }

    &__content {
      border-left: 2px solid $accent;

      &::before {
        border-bottom: 5px solid $accent;
      }
    }

    &__entry {
      width: 100%;

      &--active {
        .timeline__dot{
          &_icon {
            color: #fff;
            border-color: $accent;
            background-color: $accent;
          }
          &::after {
            border-bottom: 2px dotted $grey;
          }
        }
      }

      /* Before all
      &:not(.timeline__entry.timeline__entry--active ~ .timeline__entry):not(.timeline__entry.timeline__entry--active) {}
      */

      // After all
      &.timeline__entry--active ~ .timeline__entry {
        .timeline__dot {
          &_icon {
            border-color: $grey;
            color: #fff;
            background-color: $grey;
          }

          &_label,
          &_content {
            color: $grey;
          }

          &::after {
            border-bottom: 2px dotted $grey;
          }
        }

        .timeline__header {
          &_label,
          &_content {
            color: $grey;
          }
        }

        .timeline__content {
          border-left: 2px solid $grey;

          &::before {
            border-bottom: 5px solid $grey;
          }
        }
      }
    }
    iot-platform-ui-timeline-entry.timeline__entry:last-child .timeline__dot::after {
      border-bottom: none;
    }
  }
}
