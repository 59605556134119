@use '@angular/material' as mat;
@use "sass:map";

@mixin asset-variable-formula-configure-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);
  $warn-color: mat.m2-get-color-from-palette(map.get($theme, warn), lighter);

  .asset-variable-formula-configure--success {
    border-radius: 4px;
    border: 1px solid $accent;
    color: $accent;
    background-color: #fff;
  }

  .asset-variable-formula-configure--error {
    border-radius: 4px;
    border: 1px solid $warn;
    color: $warn;
    background-color: #fff;
  }
}
