@use '@angular/material' as mat;
@use "sass:map";

@mixin calendar-details-shell-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);

  .show-previous-days-off {
    color: $accent;
  }
}
