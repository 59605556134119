@use '@angular/material' as mat;

@mixin iot-platform-ui-async-autocomplete-theme($theme) {
  .iot-platform-ui-async-autocomplete {
    &__select-field{
      .iot-platform-ui-form-field {
        position: relative;
        top: 3px
      }
    }
  }
}
