@use '@angular/material' as mat;

@mixin iot-platform-users-user-detail-dialog-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map-get($theme, accent), default);
  $green: mat.m2-get-color-from-palette(map-get($theme, accent), default);
  $warn: mat.m2-get-color-from-palette(map-get($theme, warn), default);
  $blue300: map-get($theme, al-color-palette-blue300);
  $orange300: map-get($theme, al-color-palette-orange300);
  $grey300: map-get($theme, al-color-palette-grey300);

  .user-detail-dialog--container {
    color: $primary-color;
  }

  .user-detail-dialog--title {
    color: $accent;
  }

  .user-detail-popup--status {
    &-active {
      color: $green;
    }

    &-pending {
      color: $blue300;
    }

    &-unconfirmed {
      color: $orange300;
    }

    &-disabled {
      color: $warn;
    }
  }

  .grey,
  .out-of-scope-bp {
    color: $grey300;
  }
}
