@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-button-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);
  $brand-blue: map.get($theme, al-color-brand-blue);
  $buttonHeight: 40px;

  @include mat.icon-button-density(-1);
  @include mat.button-density(-1);

  button[disabled] {
    cursor: not-allowed;
    pointer-events: initial;
  }

  .mat-mdc-button.mat-mdc-button-base {
    height: auto;
    min-height: 36px;

    &:not([disabled]):hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  button.button-regular,
  .mat-mdc-button.mat-mdc-button-base.button-regular {
    min-width: 150px;
    height: $buttonHeight;
    font-size: 12px;
    border-radius: 2px;
    text-transform: uppercase;
  }

  button.regular-round-button {
    &:hover {
      background-color: rgba(57, 76, 90, 0.05);
    }
  }

  button.reverse-primary-button,
  button.mat-mdc-mini-fab.reverse-primary-button {
    color: $primary;
    background-color: transparent;
  }

  button.reverse-accent-button,
  button.mat-mdc-mini-fab.reverse-accent-button {
    color: $accent;
    background-color: transparent;
  }

  button.reverse-warn-button {
    color: $warn;
    background-color: transparent;
  }

  button.mat-mdc-mini-fab.light-button {
    box-shadow: none;
    color: #b0b0b0;
    background-color: transparent;

    &:hover,
    &[aria-expanded='true'] {
      background-color: rgba(57, 76, 90, 0.05);
    }
  }

  button.mat-mdc-mini-fab.transparent-button {
    box-shadow: none;
    color: $primary-color;
    background-color: transparent;

    &:hover {
      background-color: rgba(57, 76, 90, 0.05);
    }

    &[disabled='true'] {
      background-color: transparent;
    }
  }

  .mat-mdc-button:not(:disabled) {
    background: transparent;
  }

  .mat-mdc-raised-button {
    height: auto;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    min-width: 49px;
    line-height: 25px;
    font-size: 12px;

    &.mat-accent {
      color: #fff;
    }

    &.mat-mdc-button-base {
      height: $buttonHeight;
    }
  }

  .mdc-button {
    letter-spacing: normal;
    font-family: Open Sans, sans-serif;
    font-weight: 500;
    font-size: 12px;

    .mdc-button__label {
      width: inherit;
    }
  }
  .mat-mdc-card-actions {
    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-stroked-button {
      margin: 0 8px;
    }
  }

  .mat-mdc-raised-button,
  .mat-mdc-icon-button,
  .mat-mdc-button {
    .mat-icon {
      margin-top: 4px;
    }

    &[disabled][disabled] {
      color: var(--mdc-protected-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
      background-color: var(--mdc-protected-button-disabled-container-color, rgba(0, 0, 0, 0.12));
      cursor: not-allowed;
      box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
    }
  }

  .mat-mdc-mini-fab {
    font-family: Open Sans, sans-serif;
    font-weight: 500;
    color: #fff;
  }


  .mat-mdc-icon-button {
    color: inherit;

    &.mat-mdc-button-base {
      padding: 0;
    }

    &:disabled {
      background-color: transparent !important;
    }

    &.mat-warn {
      color: var(--mdc-icon-button-icon-color, inherit);
    }

    &.mat-accent {
      color: var(--mdc-icon-button-icon-color, inherit);
    }

    &.mat-mdc-focus-indicator {
      opacity: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-mdc-form-field-suffix,
  .mat-mdc-form-field-text-suffix,
  .mat-mdc-form-field-icon-suffix {
    .mat-mdc-icon-button {
      height: 1.5em;
      width: 1.5em;
      font: inherit;
      vertical-align: baseline;
      position: relative;
      top: 12px;
      right: 3px;
    }

    .mat-datepicker-toggle .mat-mdc-icon-button {
      position: inherit;
    }
  }

  .mat-mdc-button-persistent-ripple {
    display: none;
  }

  .mat-mdc-outlined-button:disabled {
    color: var(--mdc-outlined-button-disabled-label-text-color);
  }
  .mat-mdc-outlined-button {
    text-transform: capitalize;
  }

  .mat-mdc-outlined-button.mat-primary:not(:disabled) {
    border-color: $brand-blue;
  }
}
