@use 'sass:map';

@mixin iot4bos-ui-asset-groups-manage-related-assets($theme) {
  $grey50: map.get($theme, al-color-palette-grey50);
  $red300: map.get($theme, al-color-palette-red300);

  [cdkdragboundary='.manage-related-assets__drop-boundary'] {
    & :not(.cdk-drag-placeholder) > .iot-platform-ui-overview-card {
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }

  .manage-related-assets {
    .cdk-drag-placeholder {
      min-height: 106px;
    }

    .overview-card {
      padding: 8px !important;

      .overview-card-img-container {
        width: 120px !important;
        height: 80px !important;
        max-width: 120px !important;
        max-height: 80px !important;
      }

      .overview-card-header {
        margin-bottom: 8px !important;
        margin-top: 4px !important;
      }

      .overview-card-data-container {
        padding: 7px 0 !important;
      }

      .overview-card-content {
        margin-top: 0 !important;
      }

      .overview-card__property-label,
      .overview-card__property-value {
        padding-top: 0 !important;
        padding-bottom: 6px !important;
      }
    }

    &__section {
      background-color: $grey50;
    }

    &--max-assets-reached {
      color: $red300;
    }
  }
}
