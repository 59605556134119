@use '@angular/material' as mat;
@use "sass:map";

@mixin topics-configure-form-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .events-24h-result {
    color: $accent;
  }

  .events-24h-over-100 {
    color: $warn;
  }
}
