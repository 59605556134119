@use 'sass:map';

@mixin iot4bos-ui-asset-groups-information-tab($theme) {
  $grey50: map.get($theme, al-color-palette-grey50);
  $red300: map.get($theme, al-color-palette-red300);

  .asset-groups-info-tab {
    &__related-assets {
      .overview-card {
        padding: 8px !important;

        .overview-card-img-container {
          width: 120px !important;
          height: 80px !important;
          max-width: 120px !important;
          max-height: 80px !important;
        }

        .overview-card-header {
          margin-bottom: 8px !important;
          margin-top: 4px !important;
        }

        .overview-card-data-container {
          padding: 7px 0 !important;
        }

        .overview-card-content {
          margin-top: 0 !important;
        }

        .overview-card__property-label,
        .overview-card__property-value {
          padding-top: 0 !important;
          padding-bottom: 6px !important;
        }
      }

      .default-card {
        height: 84px !important;
        max-height: 84px !important;
        min-height: 84px !important;
      }
    }
  }
}
