@use '@angular/material' as mat;

@mixin iot4bos-feature-app-shell-theme($theme) {
  .sidenav {
    &::-webkit-scrollbar-track {
      background-color: #394c5a;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #728694;
    }
  }
}
