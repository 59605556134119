@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-overview-card-theme($theme) {
  $text-secondary: map.get($theme, al-color-text-secondary);
  $medium-grey: map.get($theme, al-medium-grey);
  $text-primary: map.get($theme, al-color-text-primary);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $text-white: map.get($theme, al-color-text-white);

  .overview-card-content {
    .mat-expansion-panel-header {
      height: auto !important;
    }
  }

  .overview-card-img-container--default-svg {
    .overview-card-icon.mat-icon {
      svg {
        fill: $text-white;

        path {
          stroke: $accent;
        }
      }
    }
  }

  .overview-card-edit-image-button {
    color: $text-white;
  }

  .overview-card-icon + .overview-card-edit-image-button {
    color: $text-secondary;
  }

  .overview-card,
  .iot-platform-ui-overview-card,
  iot-platform-ui-overview-card {
    color: $text-primary;
    background-color: $text-white;
    border-radius: 8px;

    &-header {
      .event-severity-display-container {
        width: auto !important;
      }
    }

    &__button-action {
      &:not(:disabled) {
        color: $text-secondary !important;
      }
    }

    .mat-expansion-panel {
      margin-bottom: 10px;

      &-spacing {
        margin: 0;
      }

      &-content {
        font: inherit;
      }

      &-body {
        padding: 0;
      }

      &-header {
        padding-right: 10px;
        padding-left: 0;

        .mat-expansion-indicator::after {
          margin-top: -10px;
        }
      }

      .overview-card-section-title {
        margin-bottom: 0;
      }
    }

    &__property,
    .iot-platform-ui-detail-popup-display-property {
      color: $medium-grey;

      &-value {
        color: $text-primary;
      }
    }

    .overview-card-section-title .detail-popup-section-header__title,
    .mat-expansion-panel-header-title,
    .overview-card__property-label,
    .overview-card__property .mat-icon {
      color: $text-secondary;
    }

    &.pending,
    &.published {
      .overview-card-name {
        padding: 7px;
        padding-right: 0 !important;
        border-radius: 5px;
      }
    }

    &.pending {
      .overview-card-name {
        @extend .bg-orange100;
      }
    }

    &.published {
      .overview-card-name {
        @extend .bg-blue100;
      }
    }

    &.deleted {
      .overview-card-name {
        @extend .bg-red300;
        color: $text-white;
      }
    }

    &__property {
      &-value {
        font-weight: 600 !important;
      }

      &.flex-col {
        flex-direction: column;
        .overview-card__property-label,
        .overview-card__property-container-value,
        .overview-card__property-value {
          flex: 1 1 100% !important;
          width: 100% !important;
        }

        .overview-card__property-label {
          max-height: max-content !important;
        }

        .overview-card__property-value {
          line-height: 16px !important;
        }
      }

      &.pending,
      &.published {
        .overview-card__property-value {
          margin-right: 0 !important;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .overview-card__property-value-meta-icon {
          padding: 1px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &.pending {
        .overview-card__property-value,
        .overview-card__property-value-meta-icon {
          @extend .bg-orange100;
        }
      }

      &.published {
        .overview-card__property-value,
        .overview-card__property-value-meta-icon {
          @extend .bg-blue100;
        }
      }

      &.deleted {
        .overview-card__property-value,
        .overview-card__property-value-meta-icon {
          @extend .bg-red300;
          color: $text-white;
        }
      }
    }
  }
}
