@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-tab-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);


  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    flex-grow: initial;
    min-width: 160px;
    justify-content: center;
  }

  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: $accent;
  }


  .mat-mdc-tab-group {
    &.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
      flex-grow: initial;
      min-width: 160px;
    }

    .mat-mdc-tab-header,
    .mat-mdc-tab-header .mdc-tab {
      height: 31px;
      border-bottom: none;
    }

    .mat-mdc-tab-header {
      .mdc-tab,
      .mat-tab-label {
        height: 31px;
        font-size: 12px;
        color: $primary-color;

        &.mat-tab-label-active,
        &.mdc-tab--active {
          opacity: 1;
          color: $accent;
          font-weight: 600;
        }
      }
    }

    .mat-tab-labels,
    .mat-mdc-tab-labels {
      background-color: #ffff;
      justify-content: center;
    }
  }
}
