@use '@angular/material' as mat;

@mixin master-view-table-theme($theme) {

  .table-container {

    mat-paginator,
    .mat-mdc-paginator {
      .mat-mdc-paginator-page-size-select {
        width: 55px;
      }
    }

    .mat-mdc-table {
      .mdc-data-table__header-cell {
        padding: 0;
      }

      .mdc-data-table__header-row {
        height: 40px;
      }

      .mdc-data-table__row {
        height: 35px;

        td.mat-mdc-cell {
          padding: 0;
        }

        &:not(.mdc-data-table__row--selected):hover {
          border-left-color: #8ed0ff;
          background-color: #fff;
          box-shadow: 0 5px 10px #0000001a;

          &.selected,
          &.checked {
            border-left-color: #4f78b7;
          }
        }
      }
    }

    &--sticky {
      &::-webkit-scrollbar {
        opacity: 0;
        background: transparent;
        height: auto;
        width: 5px;
      }

      &::-webkit-scrollbar:hover {
        opacity: 1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: #d0d0d070;
      }

      .mat-mdc-table thead,
      mat-paginator {
        z-index: 1;
        position: sticky;
        background-color: #f8f8f8 !important;
      }

      .mat-mdc-table thead {
        top: 0px;

        th {
          background-color: #f8f8f8 !important;
        }
      }

      mat-paginator,
      .mat-mdc-paginator {
        bottom: 0px;
      }
    }
  }
}
