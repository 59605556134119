@use '@angular/material' as mat;
@use "sass:map";

@mixin iot-platform-ui-header-theme($theme) {
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  button.navbar-business-profile-menu {
    &.mat-mdc-button[disabled][ng-reflect-disabled='true'] {
      color: $primary-color;
    }
  }

  .navbar-business-profile-select {
    &.mat-mdc-select-disabled {
      color: inherit;

      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }
  }

  .bp-list--bp-name {
    font-size: 14px !important;
  }

  header {
    color: $primary-color;
  }

  .border-right {
    &::after {
      border-right: 1px solid $primary-color;
    }
  }

  .border-left {
    &::before {
      border-left: 1px solid $primary-color;
    }
  }
}
