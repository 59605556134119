@use '@angular/material' as mat;

@mixin log-details-theme($theme) {
  .log-details {
    &--selected-log {
      background-color: #e9eff6;
    }

    &__detail-section-old-image {
      background-color: #edc3cb;
    }

    &__detail-section-new-image {
      background-color: #edf5ed;
    }
  }
}
