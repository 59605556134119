@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-tooltip-theme($theme) {
  $primary-darker: mat.m2-get-color-from-palette(map.get($theme, primary), darker);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);
  $warn-color: mat.m2-get-color-from-palette(map.get($theme, warn), default-contrast);

  .mat-mdc-tooltip {
    border-radius: 5px;
    --mdc-plain-tooltip-supporting-text-size: 10px;

    .mdc-tooltip__surface {
      letter-spacing: normal;
    }

    &.regular-tooltip,
    &.regular-tooltip .mdc-tooltip__surface {
      background-color: $primary-darker;
      max-width: 300px;
      white-space: pre-line;
    }

    &.warning-tooltip,
    &.warning-tooltip .mdc-tooltip__surface {
      background-color: $warn;
      color: $warn-color;
    }
  }
}
