@use "sass:map";

@mixin iot-platform-ui-popup-component-theme($theme) {
  $blue100: map.get($theme, al-color-palette-blue100);
  $orange100: map.get($theme, al-color-palette-orange100);

  .iot-platform-ui-popup {
    &.pending {
      .popup-header,
      .popup-confirm {
        background-color: $blue100 !important;
      }
    }

    &.published {
      .popup-header,
      .popup-confirm {
        background-color: $orange100 !important;
      }
    }
  }
}
