@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-ui-download-card-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $grey: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-grey-palette));
  // $grey: mat.m2-get-color-from-palette(mat.define-palette($mat-grey));

  .download-card {
    &_box {
      .mat-icon {
        color: $grey;
      }

      .mat-mdc-button {
        background-color: $grey;
      }
    }

    .download-card_box {
      &:hover {
        border-color: $accent;
        border: 3px solid $accent;

        .mat-icon {
          color: $accent;
        }

        .mat-mdc-button {
          background-color: $accent;
        }
      }
    }
  }
}
