@use '@angular/material' as mat;
@use 'sass:map';

@mixin drawer-card-theme($theme) {
  $text-primary: map.get($theme, al-color-text-primary);
  $text-secondary: map.get($theme, al-color-text-secondary);

  .drawer-card {
    box-shadow: none;
    color: $text-primary;
    width: 100%;
    height: 100%;

    .iot-platform-ui-section-header {
      color: $text-primary;
    }

    &__header {
      width: 100%;

      .mat-mdc-card-header-text {
        width: inherit;
      }

      &_close-btn {
        width: 30px;
        height: 30px;

        mat-icon {
          color: $text-secondary;
        }
      }

      &--sticky {
        padding: 16px;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: var(--mat-sidenav-container-background-color);
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
      }
    }

    &__header_title,
    .iot-platform-ui-section-header__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }

    iot-platform-ui-overview-card .overview-card {
      box-shadow: none !important;
    }
  }
}
